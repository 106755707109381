import {
  CreditCardOutlined,
  DriveFileRenameOutline,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Grid,
  ToggleButton,
  Stack,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import { isEqual } from "lodash";
import CreditCardEditOutlineIcon from "mdi-react/CreditCardEditOutlineIcon";
import { FC, useRef, MouseEvent, ChangeEvent, FocusEvent } from "react";

import { useMessager, useSession } from "@/hooks";
import useDocument from "@/hooks/useDocument";
import {
  EDocumentSignerRoles,
  IDocumentSigner,
} from "@/state/Document/slice.types";

import {
  validationSchema,
  initialValues,
  ParticipantValues,
} from "./SignersModal.schema";
import {
  roleIconContainerSx,
  roleTypographySx,
  gridSx,
  centerSx,
} from "./SignersModal.styles";
import { ISignersModalProps } from "./SignersModal.types";

const SignersModal: FC<ISignersModalProps> = ({
  participant = 1,
  open,
  signer = null,
  onClose,
}) => {
  const formikRef = useRef<FormikProps<ParticipantValues>>(null);
  const { setMessage } = useMessager();
  const { sessionSignatureCreditsSelectorSt } = useSession();
  const { addNewSigner, updateSignerFromDocument, documentSignersSt } =
    useDocument();

  const handleClose = () => {
    formikRef.current?.resetForm();
    onClose && onClose();
  };

  const filterOnlySigners = (): IDocumentSigner[] => {
    return documentSignersSt.filter(
      (sg: IDocumentSigner) => sg.role === EDocumentSignerRoles.SIGNER,
    );
  };

  const onSubmit = (values: ParticipantValues) => {
    if (!signer) {
      const onlySigners = filterOnlySigners();
      if (onlySigners.length <= 10) {
        addNewSigner({
          ...values,
          role: values.role as EDocumentSignerRoles,
          require_video:
            values.role !== EDocumentSignerRoles.PAYER
              ? (values.require_video as boolean)
              : undefined,
        });
        // setSubmitting(false);
      } else {
        setMessage({
          title: "No puede añadir mas firmantes al contrato",
          body: "Ha alcanzado el número máximo de firmantes por contrato, por favor verifique el listado de firmantes.",
          open: true,
          type: "modal",
          buttonCloseTitle: "Entendido",
        });
      }
    } else {
      updateSignerFromDocument({
        prevUuid: signer?.uuid,
        data: values as IDocumentSigner,
      });
    }
    onClose && onClose();
  };

  /**
   * TODO: Probar casos de prueba para el formulario:
   * 1) Crear mas de un firmante;
   * 2) Crear mas de 10 firmantes;
   * 3) Crear 2 firmantes con mismo RFC e Email;
   * 4) Actualizar datos de un firmante agregado
   */

  const isRfcUnique = (rfc: string) => {
    if (!signer) {
      return documentSignersSt.some((sg: { rfc: string }) => sg.rfc === rfc);
    }
    const signersDiff = documentSignersSt.filter(
      (sg: { id: number | undefined }) => sg.id !== signer?.id,
    );

    return signersDiff.some((sg: { rfc: string }) => sg.rfc === rfc);
  };

  const isEmailUnique = (email: string) => {
    if (!signer) {
      return documentSignersSt.some(
        (sg: { email: string }) => sg.email === email,
      );
    }
    const signersDiff = documentSignersSt.filter(
      (sg: { id: number | undefined }) => sg.id !== signer?.id,
    );

    return signersDiff.some((sg: { email: string }) => sg.email === email);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid='signers-modal-component'
      fullWidth={true}
      maxWidth='md'
    >
      <DialogTitle>Participante {participant}</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={signer ?? initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          // validateOnChange={false}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            handleBlur,
            touched,
            isValid,
            dirty,
            setFieldError,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent='center' spacing={2} sx={gridSx}>
                <Grid item xs={12} md={6}>
                  <TextField
                    id='first_name'
                    name='first_name'
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Nombres'
                    placeholder='Escribe...'
                    variant='outlined'
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={
                      Boolean(touched.first_name && errors.first_name) &&
                      errors.first_name
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id='last_name'
                    name='last_name'
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Apellido paterno'
                    placeholder='Escribe...'
                    variant='outlined'
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={
                      Boolean(touched.last_name && errors.last_name) &&
                      errors.last_name
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id='mother_last_name'
                    name='mother_last_name'
                    value={values.mother_last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Apellido materno'
                    placeholder='Escribe...'
                    variant='outlined'
                    error={Boolean(
                      touched.mother_last_name && errors.mother_last_name,
                    )}
                    helperText={
                      Boolean(
                        touched.mother_last_name && errors.mother_last_name,
                      ) && errors.mother_last_name
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type='email'
                    id='email'
                    name='email'
                    value={values.email}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      setFieldValue("email", value);
                      if (isEmailUnique(value)) {
                        setFieldError("email", "Correo electrónico duplicado.");
                      }
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (isEmailUnique(values.email)) {
                        setFieldError("email", "Correo electrónico duplicado.");
                      } else {
                        handleBlur(e);
                      }
                    }}
                    label='Correo electrónico'
                    placeholder='Escribe...'
                    variant='outlined'
                    error={Boolean(touched.email && errors.email)}
                    helperText={
                      Boolean(touched.email && errors.email) && errors.email
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id='rfc'
                    name='rfc'
                    value={values.rfc}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      setFieldValue("rfc", value);
                      if (isRfcUnique(value)) {
                        setFieldError("rfc", "Documento RFC duplicado.");
                      }
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                      if (isRfcUnique(values.rfc)) {
                        setFieldError("rfc", "Documento RFC duplicado.");
                      } else {
                        handleBlur(e);
                      }
                    }}
                    label='RFC'
                    placeholder='Escribe...'
                    variant='outlined'
                    error={Boolean(touched.rfc && errors.rfc)}
                    helperText={
                      Boolean(touched.rfc && errors.rfc) && errors.rfc
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    paragraph
                    variant='body1'
                    fontWeight='bold'
                    align='center'
                  >
                    ¿Qué rol desempeñará el participante?
                  </Typography>
                  <Stack
                    direction='row'
                    justifyContent='space-evenly'
                    spacing={2}
                  >
                    <Box sx={roleIconContainerSx}>
                      <ToggleButton
                        color='atmosphere'
                        value={`${EDocumentSignerRoles.PAYER}`}
                        selected={values.role === EDocumentSignerRoles.PAYER}
                        onChange={(
                          event: MouseEvent<HTMLElement>,
                          newAlignment: string | null,
                        ) => setFieldValue("role", newAlignment)}
                        disabled={
                          sessionSignatureCreditsSelectorSt &&
                          sessionSignatureCreditsSelectorSt.remaining_quantity >
                            0
                        }
                      >
                        <CreditCardOutlined />
                      </ToggleButton>
                      <Typography
                        variant='caption'
                        align='center'
                        sx={roleTypographySx}
                      >
                        Pagador
                      </Typography>
                    </Box>
                    <Box sx={roleIconContainerSx}>
                      <ToggleButton
                        color='atmosphere'
                        selected={values.role === EDocumentSignerRoles.SIGNER}
                        value={`${EDocumentSignerRoles.SIGNER}`}
                        onChange={(
                          event: MouseEvent<HTMLElement>,
                          newAlignment: string | null,
                        ) => setFieldValue("role", newAlignment)}
                      >
                        <DriveFileRenameOutline />
                      </ToggleButton>
                      <Typography
                        variant='caption'
                        align='center'
                        sx={roleTypographySx}
                      >
                        Firmante
                      </Typography>
                    </Box>
                    <Box sx={roleIconContainerSx}>
                      <ToggleButton
                        color='atmosphere'
                        selected={
                          values.role === EDocumentSignerRoles.SIGNER_PAYER
                        }
                        value={`${EDocumentSignerRoles.SIGNER_PAYER}`}
                        onChange={(
                          event: MouseEvent<HTMLElement>,
                          newAlignment: string | null,
                        ) => setFieldValue("role", newAlignment)}
                        disabled={
                          sessionSignatureCreditsSelectorSt &&
                          sessionSignatureCreditsSelectorSt.remaining_quantity >
                            0
                        }
                      >
                        <CreditCardEditOutlineIcon />
                      </ToggleButton>
                      <Typography
                        variant='caption'
                        align='center'
                        sx={roleTypographySx}
                      >
                        Pagador y <br />
                        Firmante
                      </Typography>
                    </Box>
                  </Stack>
                  {Boolean(errors.role) && (
                    <FormHelperText
                      error={Boolean(errors.role)}
                      sx={{ textAlign: "center" }}
                    >
                      {errors.role}
                    </FormHelperText>
                  )}
                </Grid>
                {values.role !== "" &&
                  values.role !== EDocumentSignerRoles.PAYER && (
                    <Grid item xs={12} sx={centerSx}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name='require_video'
                              checked={values.require_video}
                              value={values.require_video}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                              ) =>
                                setFieldValue(
                                  "require_video",
                                  event.target.checked,
                                )
                              }
                            />
                          }
                          label='¿El firmante debe grabar el video de validación?'
                        />
                      </FormGroup>
                    </Grid>
                  )}
                <Grid item xs={12} md={6} lg={4}>
                  <Button
                    size='large'
                    variant='contained'
                    fullWidth
                    type='submit'
                    disabled={
                      !isValid || isEqual(initialValues, values) || !dirty
                    }
                  >
                    Agregar Participante
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SignersModal;
