import {
  Typography,
  Box,
  Grid,
  Slider,
  Button,
  Alert,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import ArchiveEditOutlineIcon from "mdi-react/ArchiveEditOutlineIcon";
import { FC, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { Permissions } from "@/helpers/permissions";
import {
  useSignaturePackage,
  useSession,
  useMessager,
  useLoader,
} from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import {
  useLazyGetAllSignaturePackagesQuery,
  usePurchaseSignaturePackageMutation,
} from "@/service";
import { ISignaturePackage } from "@/state/SignaturePackage/slice.types";

import {
  initialValues,
  validationSchema,
  SignaturePackagePurchaseValues,
} from "./SignaturePurchaseForm.schema";
import {
  signaturePricerSx,
  containerSx,
  alertSx,
} from "./SignaturePurchaseForm.styles";
import { SignatureCreditBar } from "../SignatureCreditBar";
import { SocialFooter } from "../SocialFooter";

const SignaturePurchaseForm: FC = () => {
  const navigate = useNavigate();
  const [getAllSignaturePackages, { isLoading, isSuccess }] =
    useLazyGetAllSignaturePackagesQuery();
  const [
    purchaseSignaturePackage,
    { isSuccess: isPostSuccess, isLoading: isPostLoading },
  ] = usePurchaseSignaturePackageMutation();
  const { setMessage, clearMessage } = useMessager();
  const { setLoader } = useLoader();
  const { sessionUserSt, sessionSignatureCreditsSelectorSt: signature_credit } =
    useSession();
  const { signaturePackagesSt } = useSignaturePackage();

  useEffect(() => {
    getAllSignaturePackages();
  }, []);

  const handleOnClose = () => {
    navigate("/dashboard/v1/create-document");
  };

  useEffect(() => {
    if (isPostSuccess) {
      setLoader(false);
      setMessage({
        title: "Solicitud de compra realizada con éxito",
        body: (
          <Alert severity='success'>
            Se ha realizado la solicitud de compra con éxito. Le llegará un
            correo eléctronico con un enlace para proceder al pago de la compra
            del paquete de firmas.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      });
    }
  }, [isPostLoading, isPostSuccess]);

  const onCreateSignaturePackage = (values: SignaturePackagePurchaseValues) => {
    setLoader(true);
    const { quantity } = values;
    const currentPackage = signaturePackagesSt.find(
      (pack: ISignaturePackage) =>
        Number(quantity) >= pack.min_quantity &&
        Number(quantity) <= pack.max_quantity,
    );
    purchaseSignaturePackage({
      quantity,
      package_id: currentPackage.id,
      user_id: sessionUserSt.id,
    });
  };

  const onSubmit = (values: SignaturePackagePurchaseValues) => {
    setMessage({
      title: "Realizar solicitud de compra de créditos",
      body: `¿Está seguro que desea realizar la solicitud de compra de (${values.quantity}) créditos de firma?`,
      open: true,
      buttonCloseTitle: "Cancelar",
      onClose: clearMessage,
      onConfirm: () => onCreateSignaturePackage(values),
      confirmTitle: "Entendido",
      type: "modal",
    });
  };

  const marks = signaturePackagesSt.map((pack: ISignaturePackage) => ({
    label: pack.name,
    value: pack.max_quantity,
  }));

  const getPackagePriceByQuantity = (quantity: number) => {
    const currentPackage = signaturePackagesSt.find(
      (pack: ISignaturePackage) =>
        quantity >= pack.min_quantity && quantity <= pack.max_quantity,
    );
    if (currentPackage) {
      return currentPackage.price_per_signature;
    }
    return 0;
  };

  return (
    <AuthorizedRoute
      allowedPermission={Permissions.PURCHASE_USER_SIGNATURE_CREDITS}
    >
      <Box sx={containerSx} data-testid='purchase-credits-form-component'>
        <Grid container justifyContent='center' spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h4' align='center' color='primary'>
              Comprar Créditos de Firmas
            </Typography>
          </Grid>
          {signature_credit?.remaining_quantity > 0 && (
            <Grid item xs={12} md={8} lg={7}>
              <SignatureCreditBar />
            </Grid>
          )}
          {signature_credit?.remaining_quantity === 0 && (
            <Grid item xs={12} md={8} lg={7}>
              <Alert severity='info' sx={alertSx}>
                <strong>ATENCIÓN:</strong> Sus créditos se han agotado. A partir
                de ahora, las firmas deberan ser pagadas con el documento ó
                puede comprar créditos de firma nuevamente.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} md={8} lg={7}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} justifyContent='center'>
                    <Grid item xs={12}>
                      <Typography variant='body1' align='center'>
                        Selecciona un monto:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} md={9} lg={10}>
                          <Slider
                            name='quantity'
                            value={Number(values.quantity)}
                            onChange={handleChange}
                            aria-label='input-slider'
                            valueLabelDisplay='auto'
                            min={10}
                            max={5000}
                            disabled={isLoading}
                            marks={marks}
                          />
                        </Grid>
                        <Grid item xs={12} md={3} lg={2}>
                          <TextField
                            variant='outlined'
                            value={values.quantity}
                            size='small'
                            fullWidth
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                "quantity",
                                event.target.value === ""
                                  ? ""
                                  : Number(event.target.value),
                              )
                            }
                            onBlur={() => {
                              if (
                                Number(values.quantity) < 0 ||
                                Number(values.quantity) < 10
                              ) {
                                setFieldValue("quantity", 0);
                              } else if (Number(values.quantity) > 5000) {
                                setFieldValue("quantity", 5000);
                              }
                            }}
                            disabled={isLoading}
                            inputProps={{
                              min: 10,
                              max: 5000,
                              type: "number",
                              // "aria-labelledby": "input-slider",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {Boolean(errors.quantity) && (
                            <FormHelperText
                              error={Boolean(errors.quantity)}
                              sx={{ textAlign: "center" }}
                            >
                              {errors.quantity}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {isSuccess && (
                      <Grid item xs={12}>
                        <Box sx={signaturePricerSx}>
                          <Typography
                            variant='h6'
                            align='center'
                            color='primary'
                          >
                            El válor de venta es:
                          </Typography>
                          <Typography variant='body1' align='center'>
                            $
                            {(
                              getPackagePriceByQuantity(
                                Number(values.quantity),
                              ) * Number(values.quantity)
                            ).toLocaleString("es-ES")}{" "}
                            MXN + IVA
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='contained'
                        type='submit'
                        fullWidth
                        disabled={isLoading || isPostLoading || !isValid}
                        endIcon={<ArchiveEditOutlineIcon />}
                      >
                        Comprar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
          <Grid item xs={12}>
            <SocialFooter />
          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default SignaturePurchaseForm;
