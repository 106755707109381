import {
  configureStore,
  Reducer,
  AnyAction,
  EnhancedStore,
} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { apiSlice } from "@/service";
import { procedureSlice } from "@/state/Document/slice";
import { loaderSlice } from "@/state/Loader/slice";
import { messagerSlice } from "@/state/Messager/slice";
import { navigationSlice } from "@/state/Navigation/slice";
import { paymentSlice } from "@/state/Payment/slice";
import { permissionSlice } from "@/state/Permission/slice";
import { roleSlice } from "@/state/Role/slice";
import { sessionSlice } from "@/state/Session/slice";
import { signatureInventorySlice } from "@/state/SignatureInventory/slice";
import { signaturePackageSlice } from "@/state/SignaturePackage/slice";
import { signerValidationSlice } from "@/state/SignerValidation/slice";
import { statisticsSlice } from "@/state/Statistic/slice";
import { userSlice } from "@/state/User/slice";

const appReducer = combineReducers({
  session: sessionSlice.reducer,
  document: procedureSlice.reducer,
  navigation: navigationSlice.reducer,
  messager: messagerSlice.reducer,
  loader: loaderSlice.reducer,
  signer_validation: signerValidationSlice.reducer,
  signature_inventory: signatureInventorySlice.reducer,
  signature_packages: signaturePackageSlice.reducer,
  role: roleSlice.reducer,
  user: userSlice.reducer,
  payment: paymentSlice.reducer,
  permission: permissionSlice.reducer,
  statistics: statisticsSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const END_SESSION = "END_SESSION";

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === END_SESSION) {
    storage.removeItem("persist:root");
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: EnhancedStore = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export const endSession = () => {
  return {
    type: END_SESSION,
  };
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
