import {
  DriveFileRenameOutline,
  PeopleOutlined,
  DescriptionOutlined,
  CreditScore,
} from "@mui/icons-material";
import { Box, Grid, Skeleton } from "@mui/material";
import { FC, useEffect } from "react";

import { useStatistic } from "@/hooks";
import { useLazyGetAllTotalsQuery } from "@/service";

import { StatCard } from "../StatCard";

const AdminDashboard: FC = () => {
  const [getAllTotals, { isFetching }] = useLazyGetAllTotalsQuery();
  const { totalsSt } = useStatistic();

  useEffect(() => {
    getAllTotals();
  }, []);

  return (
    <Box id='admin-dashboard-component'>
      {isFetching && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={3}>
            <Skeleton variant='rounded' width='100%' height={82} />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Skeleton variant='rounded' width='100%' height={82} />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Skeleton variant='rounded' width='100%' height={82} />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <Skeleton variant='rounded' width='100%' height={82} />
          </Grid>
        </Grid>
      )}
      {totalsSt && !isFetching && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={3}>
            <StatCard
              title='Documentos completados'
              value={totalsSt.total_documents}
              icon={<DescriptionOutlined />}
              variant='solid'
              color='atmosphere'
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <StatCard
              title='Usuarios registrados'
              value={totalsSt.total_users}
              icon={<PeopleOutlined />}
              variant='solid'
              color='grass'
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <StatCard
              title='Firmas realizadas'
              value={totalsSt.total_signatures}
              icon={<DriveFileRenameOutline />}
              variant='solid'
              color='autumn'
            />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
            <StatCard
              title='Pagos realizados'
              value={totalsSt.total_payments}
              icon={<CreditScore />}
              variant='solid'
              color='pimp'
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default AdminDashboard;
