import { EDocumentSignerRoles } from "@/state/Document/slice.types";

export enum EServiceStatus {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface IServiceErrorSatus {
  error:
    | {
        message: string | null;
        code: string | number | null;
      }
    | boolean
    | null;
}

export interface IValidationProps {
  contract_id: string;
  signerr_id: string;
  verification_code: string;
}

export interface IValidationIdProps {
  file: string;
  imgIdentityFront: string;
  imgIdentityBack: string;
  contractID: string;
  signerID: string;
}

export interface IForwardEmailProps {
  documentId: number;
  signerId: number;
}

export interface ISessionsLoginProps {
  email: string;
  password: string;
}

export interface ISessionsCreateUserProps {
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  phone: string;
  email: string;
  password: string;
  username?: string;
  role?: number;
}

export interface ICreateDocumentProps {
  document_type_fee_id: number;
  owner_id: number;
  signer_count: number;
  owner_type: string;
  file: string;
  page_sign: number;
  signers: Signer[];
}

export interface Signer {
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  email: string;
  role: EDocumentSignerRoles;
  signer_type: string;
  portion: number;
  signature_code: string;
  is_signed: boolean;
  require_video: boolean;
}

export interface IPurchaseSignaturePackage {
  quantity: number;
  package_id: number;
  user_id: number;
}

export interface ISignatureCredit {
  quantity: number;
  user_id: number;
}

export interface Filter {
  [x: string]: string;
}

export interface Paginate {
  page?: number;
  per_page?: number;
  filters?: Filter[];
  sort_by?: string;
  sort_order?: string;
  search?: Filter;
}

export interface IStatisticTotal {
  total_documents: number;
  total_users: number;
  total_signatures: number;
  total_payments: number;
}
