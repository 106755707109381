import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IStatisticTotal } from "@/service/service.types";

import { IStatisticState } from "./slice.types";

const initialState: IStatisticState = {
  totals: undefined,
  error: false,
};

export const statisticsSlice = createSlice({
  initialState,
  name: "statistics",
  reducers: {
    setTotals: (
      state: IStatisticState,
      { payload }: PayloadAction<IStatisticTotal>,
    ) => {
      state.totals = payload;
    },
    setError: (
      state: IStatisticState,
      { payload }: PayloadAction<IStatisticState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setTotals, setError } = statisticsSlice.actions;
