import { Theme, SxProps } from "@mui/material";

export const cardContentSx: SxProps<Theme> = () => ({
  "&:last-child": {
    paddingBottom: "16px",
  },
});

export const iconContainerSx: SxProps = () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
});

export const outlinedCardVariantSx: SxProps<Theme> = theme => ({
  backgroundColor: "transparent",
  border: `solid 1px ${theme.palette.common.black}`,
});

export const titleSx: SxProps<Theme> = () => ({
  color: "#32325d",
  fontWeight: "bold",
});

export const textSx: SxProps<Theme> = () => ({
  color: "#32325d",
});
