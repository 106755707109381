import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import CountUp from "react-countup";

import { StatCardColor, StatCardVariant } from ".";
import { cardContentSx, iconContainerSx } from "./StatCard.styles";

interface StatCardProps {
  icon: ReactNode;
  title: string;
  value: number;
  iconPosition?: "right" | "left";
  description?: string;
  color?: StatCardColor;
  variant?: StatCardVariant;
  suffix?: string;
}

const StatCard: FC<StatCardProps> = ({
  title,
  value,
  description,
  icon,
  iconPosition = "right",
  variant = "outlined",
  color = "atmosphere",
  suffix,
}) => {
  return (
    <Card variant={variant} color={color}>
      <CardContent sx={cardContentSx}>
        <Grid container spacing={2}>
          {icon && iconPosition === "left" && (
            <Grid item xs={3} md={3}>
              <Box sx={iconContainerSx}>{icon}</Box>
            </Grid>
          )}
          <Grid item xs={9} md={9}>
            <Typography
              variant='h4'
              component='p'
              textAlign={iconPosition === "right" ? "left" : "right"}
            >
              <CountUp start={0} end={value} suffix={suffix} />
            </Typography>
            <Typography
              variant='body1'
              component='p'
              margin={0}
              textAlign={iconPosition === "right" ? "left" : "right"}
            >
              {title}
            </Typography>
            {description && (
              <Typography
                variant='caption'
                component='p'
                textAlign={iconPosition === "right" ? "left" : "right"}
              >
                {description}
              </Typography>
            )}
          </Grid>
          {icon && iconPosition === "right" && (
            <Grid item xs={3} md={3}>
              <Box sx={iconContainerSx}>{icon}</Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatCard;
