import { useDispatch, useSelector } from "react-redux";

import { IStatisticTotal } from "@/service/service.types";
import { totalsSelector } from "@/state/Statistic/selectors";
import { setTotals as setTotalsAction } from "@/state/Statistic/slice";

const useStatistic = () => {
  const dispatch = useDispatch();
  const totalsSt = useSelector(totalsSelector);

  const setTotals = (totals: IStatisticTotal) =>
    dispatch(setTotalsAction(totals));

  return {
    totalsSt,
    setTotals,
  };
};

export default useStatistic;
