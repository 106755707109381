import { EDocumentSignerRoles } from "@/state/Document/slice.types";

export interface IProgressStatusProps {
  progress: string;
  status: "progress" | "pending" | "complete";
}

export interface ContractTypeFeeID {
  id: string;
  contract_type_id: string;
  modality: string;
  sign_count: string;
  amount: string;
  amount_iva: string;
  iva: string;
  total: string;
  status: string;
  name: string;
}

export interface IListItemHeaderProps {
  itemId: string | number;
  primaryText: string;
  secondaryText?: string;
}

export interface IDocumentDetailDataProps {
  signers: Signer[];
  file: File;
  document: Document;
}

export interface File {
  id: number;
  code: string;
  name: string;
  document_id: number;
}

export interface Document {
  id: number;
  uuid: string;
  document_code: string;
  status: string;
  payment_status: string;
  owner_type: string;
  signatures: Signer[];
  owner: Owner;
  file: File;
  created_at: string;
}

export interface Owner {
  id: number;
  uuid: string;
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  username: string;
  email: string;
  phone: string;
  role: number;
  created_at: string;
}

export interface Signer {
  id: number;
  signature_code: string;
  rfc: string;
  first_name: string;
  last_name: string;
  mother_last_name: string;
  email: string;
  role: EDocumentSignerRoles;
  document_id: string;
  signer_type: string;
  portion: number;
  payment: number;
  iva_pay: number;
  total_pay: number;
  is_paid: boolean;
  is_prepaid: boolean;
  is_signed: boolean;
  page_sign: string;
  posX: string;
  posY: string;
  biometric_validation?: BiometricValidation;
  payment_data?: PaymentData;
  files?: IUploadedFiles[];
  link_payment?: { link_pay: string }[];
}

export interface BiometricValidation {
  id: string;
  document_id: string;
  signer_id: string;
  verification_code: string;
  has_photo_identity_uploaded: boolean;
  has_biometric_identity_uploaded: boolean;
  has_video_identity_uploaded: boolean;
  session_id: string;
  scan_id: string;
  is_done: boolean;
  require_video: boolean | null;
  current_step: EBiometricValidation;
  created_at: string;
}

export interface PaymentData {
  id: number;
  signer_id: number;
  payment_link: string;
  invoice_id: string;
  status?: string;
  method_type: string;
  created_at: Date;
}

export enum EOKStatus {
  NOT_OK = "0",
  OK = "1",
}

export enum EBiometricValidation {
  EMAIL = "Email",
  PHOTO = "Photo",
  BIOMETRY = "Biometry",
  VIDEO = "Video",
  SIGNATURE = "Signature",
  FINISH = "Finish",
}

export enum EBiometricValidationMap {
  Email = "Correo Electrónico",
  Photo = "Captura de Fotográfias",
  Biometry = "Biometría",
  Video = "Video",
  Signature = "Firma",
  Finish = "Finalizado",
}

export enum EDocumentStatus {
  CREATED = "CREATED",
  REVIEW = "REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  SIGNED_PENDING = "SIGNED_PENDING",
  SIGNED = "SIGNED",
  FINISHED = "FINISHED",
  DELETED = "DELETED",
  OTHER = "OTHER",
}

export interface IUploadedFiles {
  fileName: string;
}

export interface IProgressStatus {
  progress: "payment" | "signature" | "handed";
}

export const DocumentStatusMap = {
  CREATED: "Creado",
  REVIEW: "En Revisión",
  APPROVED: "Aprobado",
  REJECTED: "Rechazado",
  SIGNED_PENDING: "Pendiente por firmas",
  SIGNED: "Firmado",
  FINISHED: "Finalizado",
  DELETED: "Eliminado",
  OTHER: "Otro",
};

export const DocumentPaymentStatusMap = {
  PENDING: "Pago pendiente",
  PAIDOUT: "Pago realizado",
};
