import { alpha, createTheme } from "@mui/material/styles";

import { EThemeCustomization, EColors } from "./theme.types";

export let theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h3",
          body1: "p",
          body2: "span",
        },
      },
    },
    MuiCard: {
      variants: [
        // solid color
        {
          props: { variant: "solid" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grey[500],
            borderColor: theme.palette.grey[500],
            boxShadow: `0px 20px 27px 0px ${alpha(
              theme.palette.grey[500] as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.common.white,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.common.white,
            },
          }),
        },
        {
          props: { variant: "solid", color: "autumn" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.autumn?.main,
            borderColor: theme.palette.autumn?.main,
            boxShadow: `0px 20px 27px 0px ${alpha(
              theme.palette.autumn?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.common.white,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.common.white,
            },
          }),
        },
        {
          props: { variant: "solid", color: "atmosphere" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.atmosphere?.main,
            borderColor: theme.palette.atmosphere?.main,
            boxShadow: `0px 20px 27px 0px ${alpha(
              theme.palette.atmosphere?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.common.white,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.common.white,
            },
          }),
        },
        {
          props: { variant: "solid", color: "pimp" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.pimp?.main,
            borderColor: theme.palette.pimp?.main,
            boxShadow: `0px 20px 27px 0px ${alpha(
              theme.palette.pimp?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.common.white,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.common.white,
            },
          }),
        },
        {
          props: { variant: "solid", color: "grass" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.grass?.main,
            borderColor: theme.palette.grass?.main,
            boxShadow: `0px 20px 27px 0px ${alpha(
              theme.palette.grass?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.common.white,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.common.white,
            },
          }),
        },
        // Ghost cards
        {
          props: { variant: "ghost" },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.grey[500] as string, 0.2),
            borderColor: alpha(theme.palette.grey[500] as string, 0.2),
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.grey[500],
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.grey[500],
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.grey[500],
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.grey[500],
            },
          }),
        },
        {
          props: { variant: "ghost", color: "atmosphere" },
          style: ({ theme }) => ({
            backgroundColor: alpha(
              theme.palette.atmosphere?.main as string,
              0.2,
            ),
            borderColor: alpha(theme.palette.atmosphere?.main as string, 0.2),
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.atmosphere?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.atmosphere?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.atmosphere?.main,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.atmosphere?.main,
            },
          }),
        },
        {
          props: { variant: "ghost", color: "grass" },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.grass?.main as string, 0.2),
            borderColor: alpha(theme.palette.grass?.main as string, 0.2),
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.grass?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.grass?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.grass?.main,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.grass?.main,
            },
          }),
        },
        {
          props: { variant: "ghost", color: "autumn" },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.autumn?.main as string, 0.2),
            borderColor: alpha(theme.palette.autumn?.main as string, 0.2),
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.autumn?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.autumn?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.autumn?.main,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.autumn?.main,
            },
          }),
        },
        {
          props: { variant: "ghost", color: "pimp" },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.pimp?.main as string, 0.2),
            borderColor: alpha(theme.palette.pimp?.main as string, 0.2),
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.pimp?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.pimp?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              color: theme.palette.pimp?.main,
              fontWeight: "bold",
            },
            "& .MuiTypography-root.MuiTypography-caption": {
              color: theme.palette.pimp?.main,
            },
          }),
        },
        // Outline cards
        {
          props: { variant: "outlined", color: "atmosphere" },
          style: ({ theme }) => ({
            borderColor: theme.palette.atmosphere?.main,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.atmosphere?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.atmosphere?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "outlined", color: "grass" },
          style: ({ theme }) => ({
            borderColor: theme.palette.grass?.main,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.grass?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.grass?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "outlined", color: "autumn" },
          style: ({ theme }) => ({
            borderColor: theme.palette.autumn?.main,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.autumn?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.autumn?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "outlined", color: "pimp" },
          style: ({ theme }) => ({
            borderColor: theme.palette.pimp?.main,
            "& .MuiTypography-root.MuiTypography-h4": {
              color: theme.palette.pimp?.main,
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.pimp?.main,
              width: theme.spacing(8),
              height: theme.spacing(8),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        // Widget Card
        {
          props: { variant: "widget" },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.grey[500]}`,
            borderLeftColor: theme.palette.grey[500],
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 10px 10px 0px ${alpha(
              theme.palette.grey[500] as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h5": {
              color: theme.palette.grey[500],
            },
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.grey[500],
              width: theme.spacing(6),
              height: theme.spacing(6),
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(4),
              height: theme.spacing(4),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "widget", color: "atmosphere" },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.atmosphere?.main}`,
            borderLeftColor: theme.palette.atmosphere?.main,
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 10px 10px 0px ${alpha(
              theme.palette.atmosphere?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h5": {
              color: theme.palette.atmosphere?.main,
            },
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.atmosphere?.main,
              width: theme.spacing(6),
              height: theme.spacing(6),
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(4),
              height: theme.spacing(4),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "widget", color: "grass" },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.grass?.main}`,
            borderLeftColor: theme.palette.grass?.main,
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 10px 10px 0px ${alpha(
              theme.palette.grass?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h5": {
              color: theme.palette.grass?.main,
            },
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.grass?.main,
              width: theme.spacing(6),
              height: theme.spacing(6),
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(4),
              height: theme.spacing(4),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "widget", color: "autumn" },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.autumn?.main}`,
            borderLeftColor: theme.palette.autumn?.main,
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 10px 10px 0px ${alpha(
              theme.palette.autumn?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h5": {
              color: theme.palette.autumn?.main,
            },
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.autumn?.main,
              width: theme.spacing(6),
              height: theme.spacing(6),
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(4),
              height: theme.spacing(4),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
        {
          props: { variant: "widget", color: "pimp" },
          style: ({ theme }) => ({
            border: `1px solid ${theme.palette.pimp?.main}`,
            borderLeftColor: theme.palette.pimp?.main,
            borderLeftWidth: "6px",
            borderLeftStyle: "solid",
            backgroundColor: theme.palette.common.white,
            boxShadow: `0px 10px 10px 0px ${alpha(
              theme.palette.pimp?.main as string,
              0.15,
            )}`,
            "& .MuiTypography-root.MuiTypography-h5": {
              color: theme.palette.pimp?.main,
            },
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.pimp?.main,
              width: theme.spacing(6),
              height: theme.spacing(6),
            },
            "& .MuiSvgIcon-root": {
              fill: theme.palette.common.white,
              width: theme.spacing(4),
              height: theme.spacing(4),
            },
            "& .MuiTypography-root.MuiTypography-body1": {
              fontWeight: "bold",
            },
          }),
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          fontWeight: "bold",
          textTransform: "capitalize",
        },
        text: {
          fontWeight: "bold",
          textTransform: "capitalize",
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: "bolder",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: "bold",
    },
    h6: {
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#004f9f",
      light: "#00adea",
      dark: "#003c77",
    },
    secondary: {
      main: "#ff326e",
      light: "#f86262",
      dark: "#cf3966",
    },
    success: {
      main: "#5cc858",
    },
    error: {
      main: "#cf3966",
    },
    info: {
      main: "#ffb676",
    },
    warning: {
      main: "#f86262",
    },
  },
  shape: {
    borderRadius: EThemeCustomization.SPACING_BASE,
  },
  shadows: [
    `none`,
    `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
    `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)`,
    `0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)`,
    `0px 4px 5px -2px rgb(0 0 0 / 20%), 0px 7px 10px 1px rgb(0 0 0 / 14%), 0px 2px 16px 1px rgb(0 0 0 / 12%)`,
    `0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)`,
    `0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)`,
    `0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)`,
    `0px 6px 7px -4px rgb(0 0 0 / 20%), 0px 11px 15px 1px rgb(0 0 0 / 14%), 0px 4px 20px 3px rgb(0 0 0 / 12%)`,
    `0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)`,
    `0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 13px 19px 2px rgb(0 0 0 / 14%), 0px 5px 24px 4px rgb(0 0 0 / 12%)`,
    `0px 7px 9px -4px rgb(0 0 0 / 20%), 0px 14px 21px 2px rgb(0 0 0 / 14%), 0px 5px 26px 4px rgb(0 0 0 / 12%)`,
    `0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%)`,
    `0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)`,
    `0px 8px 11px -5px rgb(0 0 0 / 20%), 0px 17px 26px 2px rgb(0 0 0 / 14%), 0px 6px 32px 5px rgb(0 0 0 / 12%)`,
    `0px 9px 11px -5px rgb(0 0 0 / 20%), 0px 18px 28px 2px rgb(0 0 0 / 14%), 0px 7px 34px 6px rgb(0 0 0 / 12%)`,
    `0px 9px 12px -6px rgb(0 0 0 / 20%), 0px 19px 29px 2px rgb(0 0 0 / 14%), 0px 7px 36px 6px rgb(0 0 0 / 12%)`,
    `0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 20px 31px 3px rgb(0 0 0 / 14%), 0px 8px 38px 7px rgb(0 0 0 / 12%)`,
    `0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 21px 33px 3px rgb(0 0 0 / 14%), 0px 8px 40px 7px rgb(0 0 0 / 12%)`,
    `0px 10px 14px -6px rgb(0 0 0 / 20%), 0px 22px 35px 3px rgb(0 0 0 / 14%), 0px 8px 42px 7px rgb(0 0 0 / 12%)`,
    `0px 11px 14px -7px rgb(0 0 0 / 20%), 0px 23px 36px 3px rgb(0 0 0 / 14%), 0px 9px 44px 8px rgb(0 0 0 / 12%)`,
    `0px 11px 15px -7px ${alpha("#004f9f", 0.1)}, 0px 24px 38px 3px ${alpha(
      "#004f9f",
      0,
    )}, 0px 9px 46px 8px ${alpha("#004f9f", 0)}`,
  ],
  spacing: EThemeCustomization.SPACING_BASE,
});

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h3",
          body1: "p",
          body2: "span",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: `${EColors.ATMOSPHERE}`,
          "&:hover": {
            backgroundColor: `${theme.palette.atmosphere?.dark}`,
          },
        },
      },
    },
  },
  palette: {
    autumn: theme.palette.augmentColor({
      color: {
        main: EColors.AUTUMN,
      },
      name: "autumn",
    }),
    grass: theme.palette.augmentColor({
      color: {
        main: EColors.GRASS,
      },
      name: "grass",
    }),
    pavement: theme.palette.augmentColor({
      color: {
        main: EColors.PAVEMENT,
      },
      name: "pavement",
    }),
    pimp: theme.palette.augmentColor({
      color: {
        main: EColors.PIMP,
      },
      name: "pimp",
    }),
    almostwhite: theme.palette.augmentColor({
      color: {
        main: EColors.ALMOSTWHITE,
      },
      name: "almostwhite",
    }),
    atmosphere: theme.palette.augmentColor({
      color: {
        main: EColors.ATMOSPHERE,
      },
      name: "atmosphere",
    }),
  },
});
